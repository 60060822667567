import RKLCircleClose from '../ui-kit/icons/rkl-circle-close';
import style from './leave-member-modal-error.module.css';

interface LeaveMemberModalErrorProps {
    onClose: () => void;
    message: string;
    title: string;
}

const LeaveMemberModalError: React.FC<LeaveMemberModalErrorProps> = ({
    onClose,
    title,
    message,
}) => {
    return (
        <div className={`${style.leaveMemberModalErrorContent} flex-center-column`}>
            <h3>{title}</h3>
            <span>{message}</span>
            <RKLCircleClose onClick={onClose} />
        </div>
    );
};

export default LeaveMemberModalError;
