import RKLCircleClose from '../ui-kit/icons/rkl-circle-close';
import style from './add-member-modal-error.module.css';

interface AddMemberModalErrorProps {
    onClose: () => void;
}

const AddMemberModalError: React.FC<AddMemberModalErrorProps> = ({ onClose }) => {
    return (
        <div className={`${style.addMemberModalContent} flex-center-column`}>
            <h3>You can not add a new member!</h3>
            <span>
                The maximum number of kongs in a club is 30
                <br />
                You need to remove one of the existing members
                <br />
                in order to add a new one.
            </span>
            <RKLCircleClose onClick={onClose} />
        </div>
    );
};

export default AddMemberModalError;
