import RKLWarningIcon from '../../icons/rkl-warning';
import style from './rkl-text-input.module.css';

export interface RKLTextInputProps {
    onChange: (val: string) => void;
    width?: string;
    height?: string;
    value: string;
    placeholder?: string;
    errorMessage?: string;
    error?: boolean;
    textArea?: boolean;
    maxLength?: number;
}

const RKLTextInput: React.FC<RKLTextInputProps> = ({
    placeholder,
    value,
    onChange,
    error,
    errorMessage,
    textArea,
    width,
    height,
    maxLength,
}: RKLTextInputProps) => {
    const InputErrorElement = (): JSX.Element => {
        return (
            <div className={`${style.errorWrapper} flex-center`}>
                <RKLWarningIcon color="var(--error-color)" size={18} />
                <span>{errorMessage}</span>
            </div>
        );
    };

    const handleChange = (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        const textInputValue = e.target.value;
        onChange(textInputValue);
    };

    if (textArea) {
        return (
            <div className={style.textInputContainer}>
                <div
                    className={`${style.textAreaWrapper} ${error && style.errorInput}`}
                    style={{ width, height }}
                >
                    <textarea
                        placeholder={placeholder || ''}
                        onChange={handleChange}
                        value={value}
                        autoComplete="off"
                        autoCorrect="off"
                        maxLength={maxLength}
                    ></textarea>
                </div>
                {error && InputErrorElement()}
            </div>
        );
    }

    return (
        <div className={style.textInputContainer}>
            <div
                className={`${style.inputWrapper} ${error && style.errorInput}`}
                style={{ width, height }}
            >
                <input
                    placeholder={placeholder || ''}
                    onChange={handleChange}
                    value={value}
                    type="text"
                />
            </div>
            {error && InputErrorElement()}
        </div>
    );
};

export default RKLTextInput;
