import React from 'react';
import Link from 'next/link';
import { FOOTER_MENU } from '../../app.config';
import Button from '../button';
import IcoLogoBlack from '../icons/logo-black';
import footerStyle from './footer.module.css';
import style from '../styles/styles.module.css';
import SocialLinks from '../social-links';

const Footer: React.FC = () => {
    return (
        <div className={footerStyle.footer}>
            <div className={footerStyle.inner}>
                <div className={`${footerStyle.gutter}`}>
                    <IcoLogoBlack />
                </div>
                <div className={`${footerStyle.row} ${footerStyle.gutter}`}>
                    <div className={footerStyle.calFirst}>
                        <p className={`${footerStyle.gutter} ${style.fontSizeLarge}`}>
                            The go-to sports ecosystem
                        </p>
                        <Button href="mailto:hello@rl.games" size="normal" color="rose">
                            Contact Us
                        </Button>
                    </div>
                    <div className={footerStyle.calSecond}>
                        <div
                            className={`${footerStyle.caption} ${style.fontSizeXMedium} ${style.fontColorGray}`}
                        >
                            Company
                        </div>
                        <div>
                            {FOOTER_MENU.map((menuItem, index) => (
                                <div
                                    key={`${index}-${menuItem.path || menuItem.href}`}
                                    className={`${footerStyle.menuItem} ${style.fontSizeMediumNormal} ${style.fontColorBlack}`}
                                >
                                    {menuItem.path ? (
                                        <Link href={menuItem.path}>
                                            <a>{menuItem.title}</a>
                                        </Link>
                                    ) : (
                                        <a href={menuItem.href} target="_blank" rel="noreferrer">
                                            {menuItem.title}
                                        </a>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={footerStyle.calSecond}>
                        <div
                            className={`${footerStyle.caption} ${style.fontSizeXMedium} ${style.fontColorGray}`}
                        >
                            Social
                        </div>
                        <div className={`${style.gridRow} ${footerStyle.social}`}>
                            <SocialLinks />
                        </div>
                    </div>
                </div>
                <div
                    className={`${footerStyle.copyright} ${style.fontSizeMediumNormal} ${style.fontColorLightGray}`}
                >
                    ©&nbsp;2021 Rumble Kong League. All rights reserved.
                </div>
            </div>
        </div>
    );
};

export default Footer;
