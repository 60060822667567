import React, { useEffect, useRef, useState } from 'react';
import styles from './rkl-zoom-pan-image.module.css';

interface Position {
    oldX: number;
    oldY: number;
    x: number;
    y: number;
    z: number;
}

const CANVAS_BG_COLOR = '#494a51';

interface RKLPanAndZoomImageProps {
    src: string;
    defaultDimension: number;
    dimension: number;
    handleOutput: any;
}

const RKLPanAndZoomImage: React.FC<RKLPanAndZoomImageProps> = ({
    src,
    defaultDimension,
    dimension,
    handleOutput,
}) => {
    let canvas = useRef();
    const [image, setImage] = useState<HTMLImageElement>(null);
    const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement>(null);
    const [canvasCtx, setCanvasCtx] = useState<CanvasRenderingContext2D>(null);

    const [isPanning, setPanning] = useState<boolean>(false);
    const [position, setPosition] = useState<Position>({
        oldX: 0,
        oldY: 0,
        x: 0,
        y: 0,
        z: 1,
    });

    const onMouseDown = (e) => {
        e.preventDefault();
        setPanning(true);
        setPosition({
            ...position,
            oldX: e.clientX,
            oldY: e.clientY,
        });
    };

    useEffect(() => {
        const mouseup = () => {
            setPanning(false);
        };

        const mousemove = (event) => {
            if (isPanning) {
                setPosition({
                    ...position,
                    x: position.x + event.clientX - position.oldX,
                    y: position.y + event.clientY - position.oldY,
                    oldX: event.clientX,
                    oldY: event.clientY,
                });
            }
        };

        window.addEventListener('mouseup', mouseup);
        window.addEventListener('mousemove', mousemove);

        return () => {
            window.removeEventListener('mouseup', mouseup);
            window.removeEventListener('mousemove', mousemove);
        };
    });

    useEffect(() => {
        if (!position || !canvasElement || !canvasCtx || !image) return;

        canvasCtx.clearRect(0, 0, defaultDimension, defaultDimension);

        canvasCtx.drawImage(image, position.x, position.y, dimension, dimension);

        handleOutput(canvasElement.toDataURL('image/jpeg'));
    }, [position, canvasElement, canvasCtx, image]);

    useEffect(() => {
        const canvasElem: HTMLCanvasElement = canvas.current;
        if (!canvasElem) return;
        canvasElem.style.backgroundColor = CANVAS_BG_COLOR;
        const context = canvasElem?.getContext('2d');
        setCanvasElement(canvasElem);
        setCanvasCtx(context);
    }, [canvas]);

    useEffect(() => {
        if (!canvasCtx) return;
        const image = new Image();
        image.src = src;
        setImage(image);
        image.onload = () => {
            canvasCtx.drawImage(image, 0, 0, defaultDimension, defaultDimension);
            handleOutput(canvasElement.toDataURL('image/jpeg'));
        };
    }, [canvasCtx]);

    useEffect(() => {
        if (!dimension || !canvasCtx) return;
        canvasCtx.clearRect(0, 0, defaultDimension, defaultDimension);
        canvasCtx.drawImage(image, 0, 0, dimension, dimension);
        handleOutput(canvasElement.toDataURL('image/jpeg'));
    }, [dimension]);

    return (
        <>
            <canvas
                ref={canvas}
                width={defaultDimension}
                height={defaultDimension}
                onMouseDown={onMouseDown}
                className={styles.zoomPanContainer}
            >
                Your browser does not support the HTML canvas tag.
            </canvas>
        </>
    );
};

export default RKLPanAndZoomImage;
