import React from 'react';
import { IconProps, styledIcon } from './utils';

const RKLCircleCheck: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 65C51.1208 65 65 51.1208 65 34C65 16.8792 51.1208 3 34 3C16.8792 3 3 16.8792 3 34C3 51.1208 16.8792 65 34 65ZM34 68C52.7777 68 68 52.7777 68 34C68 15.2223 52.7777 0 34 0C15.2223 0 0 15.2223 0 34C0 52.7777 15.2223 68 34 68Z"
                fill="#B1F9E0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.9919 23.6729C49.6283 24.2032 49.7143 25.1491 49.184 25.7855L32.9935 45.2141C32.7161 45.547 32.3083 45.7436 31.8751 45.7534C31.4419 45.7632 31.0256 45.5852 30.7334 45.2652L19.4001 32.8525C18.8415 32.2407 18.8846 31.292 19.4964 30.7334C20.1082 30.1748 21.057 30.2179 21.6156 30.8297L31.7896 41.9727L46.8793 23.865C47.4096 23.2285 48.3555 23.1426 48.9919 23.6729Z"
                fill="#B1F9E0"
            />
        </svg>
    );
};

export default RKLCircleCheck;
