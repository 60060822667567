import React from 'react';

const IcoLogoBlack: React.FC = () => {
    return (
        <svg
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="52" height="52" rx="12" fill="black" />
            <path
                d="M33.1406 36.0875V16.1621L36.8766 22.3888V32.3515H42.792L44.9713 36.0875H33.1406Z"
                fill="white"
            />
            <path
                d="M22.573 36.0875H18.7186L12.9733 25.9783H16.755C17.555 25.612 19.1549 24.4253 19.1549 22.6086C19.1549 20.7918 17.555 19.8249 16.755 19.5319H9.33708L7.30078 16.1621H16.755C17.9186 16.1621 22.5003 17.7005 22.5003 22.6086C22.5003 26.5351 19.8095 28.3713 18.5004 28.8353L22.573 36.0875Z"
                fill="white"
            />
            <path
                d="M28.5356 16.1621H32.3173L26.1357 26.2713L32.5181 36.0875H28.4707L22.2812 26.2713L28.5356 16.1621Z"
                fill="white"
            />
        </svg>
    );
};

export default IcoLogoBlack;
