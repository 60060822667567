import RKLCircleCheck from '../ui-kit/icons/rkl-circle-check';
import style from './success-modal.module.css';

interface SuccessModalProps {
    title: string;
    description?: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ title, description }: SuccessModalProps) => {
    return (
        <div className={`${style.successModalContent} flex-center-column`}>
            <span className={style.title}>{title}</span>
            {description && <span className={style.description}>{description}</span>}
            <RKLCircleCheck />
        </div>
    );
};

export default SuccessModal;
