import React from 'react';
import { DEFAULT_ICON_COLOR, IconProps, styledIcon } from './utils';

const RKLShootingIcon: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.88091C5.51595 1.88091 1.88091 5.51595 1.88091 10C1.88091 14.4841 5.51595 18.1191 10 18.1191C14.4841 18.1191 18.1191 14.4841 18.1191 10C18.1191 5.51595 14.4841 1.88091 10 1.88091ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                fill={props.color || DEFAULT_ICON_COLOR}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0007 5.50489C7.51807 5.50489 5.50547 7.51749 5.50547 10.0002C5.50547 12.4828 7.51807 14.4954 10.0007 14.4954C12.4834 14.4954 14.496 12.4828 14.496 10.0002C14.496 7.51749 12.4834 5.50489 10.0007 5.50489ZM3.62456 10.0002C3.62456 6.47869 6.47928 3.62398 10.0007 3.62398C13.5222 3.62398 16.3769 6.47869 16.3769 10.0002C16.3769 13.5216 13.5222 16.3763 10.0007 16.3763C6.47928 16.3763 3.62456 13.5216 3.62456 10.0002Z"
                fill={props.color || DEFAULT_ICON_COLOR}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99776 9.12852C9.51647 9.12852 9.12631 9.51868 9.12631 9.99998C9.12631 10.4813 9.51647 10.8714 9.99776 10.8714C10.4791 10.8714 10.8692 10.4813 10.8692 9.99998C10.8692 9.51868 10.4791 9.12852 9.99776 9.12852ZM7.2454 9.99998C7.2454 8.47989 8.47768 7.24761 9.99776 7.24761C11.5179 7.24761 12.7501 8.47989 12.7501 9.99998C12.7501 11.5201 11.5179 12.7523 9.99776 12.7523C8.47768 12.7523 7.2454 11.5201 7.2454 9.99998Z"
                fill={props.color || DEFAULT_ICON_COLOR}
            />
        </svg>
    );
};

export default RKLShootingIcon;
