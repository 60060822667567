import { MetaWithId } from '../models/meta';

export default function getKongsMeta(contractAddress: string): Promise<MetaWithId[]> {
    return new Promise((resolve, rejects) => {
        fetch('/meta/index.json').then((response) => {
            const jsonData = response.json();

            jsonData
                .then((response) => {
                    const items = response.data.map((item: MetaWithId) => ({
                        ...item,
                        address: contractAddress,
                    }));

                    resolve(items);
                })
                .catch((e) => {
                    rejects(e);
                });
        });
    });
}
