import style from './rkl-badge.module.css';

export enum RKLBadgeType {
    FLAT = 'flat',
    FILLED = 'filled',
    DISABLED = 'disabled',
    BLACK = 'black',
}

export interface RKLBadgeProps {
    children: React.ReactChild;
    type: RKLBadgeType;
    margin?: string;
    width?: number;
    height?: number;
    rounded?: boolean;
    clickeable?: boolean;
    onClick?: () => void;
}

const RKLBadge: React.FC<RKLBadgeProps> = ({
    children,
    rounded,
    type,
    width,
    height,
    onClick,
    margin,
    clickeable,
}: RKLBadgeProps) => {
    return (
        <div
            className={`${style.badgeContainer} ${style[type]} ${
                clickeable ? style.clickeable : ''
            } ${rounded ? style.rounded : ''} flex-center`}
            style={{ width, height, margin }}
            onClick={onClick}
        >
            <>{children}</>
        </div>
    );
};

export default RKLBadge;
