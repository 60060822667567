import React from 'react';
import { DEFAULT_ICON_COLOR, IconProps, styledIcon } from './utils';

const RKLDefenseIcon: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6207 2.05418C11.8235 1.98194 12.045 1.98194 12.2478 2.05418L20.2478 4.90418C20.62 5.03677 20.8685 5.38916 20.8685 5.78427V12.4343C20.8685 15.7216 18.5805 18.337 16.5315 20.0402C15.4834 20.9114 14.4402 21.5923 13.6613 22.0548C13.2707 22.2867 12.944 22.4652 12.7129 22.5868C12.5972 22.6476 12.5053 22.6942 12.4411 22.7263C12.409 22.7423 12.3838 22.7547 12.3659 22.7633L12.3448 22.7736L12.3365 22.7775C12.3365 22.7775 12.3351 22.7782 11.9343 21.9343C11.5334 22.7782 11.5328 22.7779 11.5328 22.7779L11.53 22.7766L11.5237 22.7736L11.5026 22.7633C11.4848 22.7547 11.4596 22.7423 11.4274 22.7263C11.3632 22.6942 11.2713 22.6476 11.1557 22.5868C10.9245 22.4652 10.5978 22.2867 10.2073 22.0548C9.42832 21.5923 8.3851 20.9114 7.33705 20.0402C5.28803 18.337 3 15.7216 3 12.4343V5.78427C3 5.38916 3.24854 5.03677 3.62074 4.90418L11.6207 2.05418ZM11.9343 21.9343L11.5328 22.7779C11.7865 22.8984 12.0815 22.8987 12.3351 22.7782L11.9343 21.9343ZM11.9343 20.8847C12.1335 20.7783 12.3977 20.632 12.7073 20.4481C13.4283 20.02 14.3851 19.3946 15.337 18.6033C17.288 16.9816 19 14.847 19 12.4343V6.44323L11.9343 3.92606L4.86854 6.44323V12.4343C4.86854 14.847 6.58052 16.9816 8.5315 18.6033C9.48344 19.3946 10.4402 20.02 11.1613 20.4481C11.4709 20.632 11.7351 20.7783 11.9343 20.8847Z"
                fill={props.color || DEFAULT_ICON_COLOR}
            />
        </svg>
    );
};

export default RKLDefenseIcon;
