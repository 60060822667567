import React, { useEffect, useState } from 'react';
import RKLButton from '../ui-kit/components/rkl-button';
import RKLTextInput from '../ui-kit/components/rkl-text-input';
import style from './change-name-modal.module.css';

interface ChangeNameModalProps {
    onClick: (name: string) => void;
    currentName?: string;
    kong?: boolean;
}

const ChangeNameModal: React.FunctionComponent<ChangeNameModalProps> = ({
    onClick,
    currentName,
    kong,
}) => {
    const [name, setName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!currentName && kong) return;
        setName(currentName);
    }, [currentName]);

    return (
        <div className={`${style.changeClubNameModalContent} flex-center-column`}>
            <span>Type the name of your {kong ? 'Kong' : 'club'}</span>
            <div className={style.inputContainer}>
                <RKLTextInput onChange={setName} value={name} placeholder="Type Club Name..." />
            </div>
            <RKLButton
                loading={loading}
                disabled={loading}
                size={'lgDesktop'}
                color={'violet'}
                onClick={() => {
                    setLoading(true);
                    onClick(name);
                }}
            >
                Save
            </RKLButton>
        </div>
    );
};

export default React.memo(ChangeNameModal);
