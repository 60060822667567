import { gql } from '@apollo/client';

// Base directories for marketplace filter
export const getVisualTraits = () => {
    return gql`
        query {
            __type(name: "VisualTraits") {
                name
                fields {
                    name
                }
            }
        }
    `;
};

// Base directories for marketplace filter
export const getBoosts = () => {
    return gql`
        query {
            __type(name: "Boosts") {
                name
                fields {
                    name
                }
            }
        }
    `;
};

/**
 * This is the minimum subset of data needed to render the marketplace
 * @returns Kong[]
 */
export const getKongs = () => {
    return gql`
        query {
            kongs {
                id
                name
                boosts {
                    defense
                    finish
                    shooting
                    vision
                }
                totalBoost
            }
        }
    `;
};

/**
 * Get My Kongs id
 * @returns string[] | number[]
 */
export const getMyKongs = () => {
    return gql`
        query Query($owner: String!) {
            myKongs(owner: $owner) {
                id
                club
                name
                kongPreviewImage
                boosts {
                    defense
                    finish
                    shooting
                    vision
                }
                visualTraits {
                    fur
                    clothes
                    eyes
                    head
                    mouth
                    background
                }
                totalBoost
            }
        }
    `;
};

/**
 * Get My Kongs id
 * @returns string[] | number[]
 */
export const getMyKongsIds = () => {
    return gql`
        query Query($owner: String!) {
            myKongs(owner: $owner) {
                id
                club
            }
        }
    `;
};

/**
 * The data for rendering the page Kong detail view.
 * @param kongId
 * @returns Kong[]
 */
export const getMetaByKongId = () => {
    return gql`
        query Query($kongId: [String!]!) {
            kong(kongId: $kongId) {
                totalBoost
                name
                kongPreviewImage
                owner
                id
                boosts {
                    defense
                    finish
                    shooting
                    vision
                }
                boostsRank {
                    defense
                    finish
                    shooting
                    vision
                }
                visualTraits {
                    fur
                    clothes
                    eyes
                    head
                    mouth
                    background
                }
                visualRarities {
                    fur
                    clothes
                    eyes
                    head
                    mouth
                    background
                }
                boosts {
                    defense
                    finish
                    shooting
                    vision
                }
                boostsRank {
                    defense
                    finish
                    shooting
                    vision
                }
            }
        }
    `;
};

/**
 * The data for rendering the Clubs view.
 * @returns Club[]
 */
export const getAvailableClubs = () => {
    return gql`
        query {
            clubs {
                id
                name
                bio
                wave
                logoImage
                color1
                color2
                roster
                owner
            }
        }
    `;
};

/**
 * @returns Club[]
 */
 export const getClubs = () => {
    return gql`
        query {
            clubs {
                id
                name
            }
        }
    `;
};

/**
 * The data for rendering the page Kong detail view.
 * @param kongId
 * @returns Kong[]
 */
export const getKongTotalBoost = () => {
    return gql`
        query Query($kongId: [String!]!) {
            kong(kongId: $kongId) {
                id
                totalBoost
            }
        }
    `;
};

/**
 * The data for rendering the page Club detail view.
 * @param clubId
 * @returns Club
 */
export const getClubDataById = () => {
    return gql`
        query Query($clubId: String!) {
            club(clubId: [$clubId]) {
                id
                name
                name
                bio
                wave
                logoImage
                color1
                color2
                roster
                owner
            }
        }
    `;
};

export const getKongsMemberData = () => {
    return gql`
        query Query($kongId: [String!]!) {
            kong(kongId: $kongId) {
                totalBoost
                name
                kongPreviewImage
                owner
                id
                boosts {
                    defense
                    finish
                    shooting
                    vision
                }
                boostsRank {
                    defense
                    finish
                    shooting
                    vision
                }
            }
        }
    `;
};

export const getPendingClubRequests = () => {
    return gql`
        query Query($clubId: String!) {
            pendingJoinClubRequestsClubs(clubId: [$clubId]) {
                kongId
                clubId
            }
        }
    `;
};

export const getAuctions = () => {
    return gql`
        query {
            auctions {
                id
                startTimestamp
                endTimestamp
                floorPrice
                minIncrement
                whitelistedCollection
                numberOfClubsAuctioned
                bidders {
                    id
                    bids {
                        amount
                        timestamp
                    }
                }
            }
        }
    `;
};

export const getPendingKongsRequest = () => {
    return gql`
        query Query($kongsIds: [String!]!) {
            pendingJoinClubRequestsKongs(kongId: $kongsIds) {
                clubId
                kongId
            }
        }
    `;
};

export const getJoinedClubsKongs = () => {
    return gql`
        query Query($kongId: [String!]!) {
            joinedClubsKongs(kongId: $kongId) {
                kongId
                clubId
                joinTimestamp
            }
        }
    `;
};
