import React from 'react';
import { DEFAULT_ICON_COLOR, IconProps, styledIcon } from './utils';

const RKLWarningIcon: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                fill={props.color || DEFAULT_ICON_COLOR}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C12.5523 7 13 7.44772 13 8L13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13L11 8C11 7.44772 11.4477 7 12 7Z"
                fill={props.color || DEFAULT_ICON_COLOR}
            />
            <path
                d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
                fill={props.color || DEFAULT_ICON_COLOR}
            />
        </svg>
    );
};

export default RKLWarningIcon;
