import { useState } from 'react';
import RKLButton from '../ui-kit/components/rkl-button';
import RKLSlideBar from '../ui-kit/components/rkl-slide-bar';
import RKLPanAndZoomImage from '../ui-kit/components/rkl-zoom-pan-image';
import style from './upload-new-logo-resize-modal.module.css';

export interface UploadNewLogoResizeModalProps {
    image: string;
    onSave: (output: string) => void;
}

const DEFAULT_DIMENSION = 350;

const UploadNewLogoResizeModal: React.FunctionComponent<UploadNewLogoResizeModalProps> = ({
    image,
    onSave,
}: UploadNewLogoResizeModalProps) => {
    const [newLogo, setNewLogo] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [barValue, setBarValue] = useState<number>(0);

    return (
        <div className={`${style.chooseKongModalContent} flex-center-column`}>
            <RKLPanAndZoomImage
                src={image}
                defaultDimension={DEFAULT_DIMENSION}
                dimension={DEFAULT_DIMENSION + barValue * 4}
                handleOutput={setNewLogo}
            />
            <div className={style.controls}>
                <RKLSlideBar value={barValue} setValue={setBarValue} />
                <RKLButton
                    loading={loading}
                    disabled={loading}
                    size={'lgDesktop'}
                    color={'violet'}
                    onClick={() => {
                        newLogo && (setLoading(true), onSave(newLogo));
                    }}
                >
                    Save
                </RKLButton>
            </div>
        </div>
    );
};

export default UploadNewLogoResizeModal;
