import React from 'react';
import IcoLogoWhite from '../../../icons/logo-white';
import loaderStyle from './rkl-modal-loader.module.css';

interface LoaderProps {
    height?: string | number;
}

const RKLModalLoader: React.FC<LoaderProps> = () => {
    return (
        <div
            className={`${loaderStyle.root} `}
        >
            <div className={`${loaderStyle.container}`}>
                <IcoLogoWhite />
            </div>
        </div>
    );
};

export default RKLModalLoader;
