import RKLCircleCheck from '../ui-kit/icons/rkl-circle-check';
import style from './choose-kong-modal-success.module.css';

const ChooseKongModalSuccess: React.FunctionComponent = () => {
    return (
        <div className={`${style.chooseKongModalContent} flex-center-column`}>
            <h3>Your request has been sent to the Club owner! </h3>
            <RKLCircleCheck />
        </div>
    );
};

export default ChooseKongModalSuccess;
