import { useLazyQuery } from '@apollo/client';
import { useEffect, useState, useContext } from 'react';
import { Kong, KongsIdsClubs } from '../../models/kong';
import { LocalData } from '../../models/local-data';
import { MetaWithId } from '../../models/meta';
import { getMyKongs } from '../../services/api/queries';
import createLocalKey from '../../services/create-local-key';
import getKongsMeta from '../../services/get-kongs-meta';
import CollectionMeta from './collection-contract';

export const collectionAddress = CollectionMeta.address;

export const useUserKongs = (kongs?: KongsIdsClubs[]) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [metadataList, setMetadataList] = useState([]);
    const [_getMyKongs] = useLazyQuery(getMyKongs());

    useEffect(() => {
        if (!kongs || kongs?.length === 0) {
            setIsLoading(false);
            return;
        }

        getMetadataBasedOnList(collectionAddress, kongs).then(
            (data) => {
                setMetadataList(data);
                setIsLoading(false);
            },
        );
    }, [kongs]);

    return { isLoading, metadataList };
};

export const isOwnedByMe = (tokenId: number, address?: string): boolean => {
    if (!address) return false;

    const storedItem: string | undefined = localStorage.getItem(createLocalKey(address));
    const storedData: LocalData | undefined = storedItem && JSON.parse(storedItem);

    if (storedData) return storedData.items.includes(String(tokenId));

    return false;
};

export const getMetadataBasedOnList = async (collectionAddress: string, list: KongsIdsClubs[]) => {
    const response: MetaWithId[] = await getKongsMeta(collectionAddress);
    const metaByKongs = list.map((kong) => {
        const current = response[Number(kong.id)];
        return {
            club: kong.club,
            ...current,
        };
    });

    return metaByKongs;
};
