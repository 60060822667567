import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { xmSmallViewportWidth } from '../../../../media-queries/index';
import { OWNER_REQUESTS_TYPE } from '../../../context/clubs-provider';
import useEnsNme from '../../../hooks/use-ens-name';
import useMediaQuery from '../../../hooks/use-media-query';
import RKLConfirmDialogue from '../rkl-confirm-modal';
import RKLLoadingSpinner from '../rkl-loading-spinner';
import RKLModalDialog from '../rkl-modal-dialog';
import cardStyles from './rkl-kong-card.module.css';

type KongCardProps = {
    imageSrc: string;
    handleRemove?: () => void;
    handleRejectOffer?: () => Promise<void>;
    handleAcceptOffer?: () => Promise<void>;
    cardType: 'viewOnly' | 'owned' | 'offered' | 'topOnly' | 'member';
    cardState: 'removed' | 'accepted' | 'rejected' | null;
    title: string;
    boost: string | number;
    attributes: object[];
    active?: boolean;
    tokenId: string;
    id?: string;
    address?: string;
    onClick?: (address: string, id: string) => void;
    kongIsPending?: boolean;
    loadingCardAction?: OWNER_REQUESTS_TYPE;
    owner?: string;
};

const RKLKongCard: React.FC<KongCardProps> = ({
    imageSrc,
    handleRemove,
    handleRejectOffer,
    handleAcceptOffer,
    cardType,
    attributes,
    title,
    boost,
    cardState,
    active,
    tokenId,
    kongIsPending,
    owner,
}) => {
    const isMatching = !useMediaQuery(xmSmallViewportWidth as MediaQueryList);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [kongId, setKongId] = useState<string>('');
    const [loadingCardAction, setLoadingCardAction] = useState<OWNER_REQUESTS_TYPE>(null);

    const router = useRouter();
    const name = useEnsNme(owner);

    const getStylesBasedOnType = (type: string) => {
        switch (type) {
            case 'viewOnly':
                return cardStyles.viewOnlyWrapper;
            case 'owned':
                return cardStyles.viewOwnedWrapper;
            case 'offered':
                return cardStyles.viewOfferedWrapper;
            case 'member':
                return cardStyles.viewMemberWrapper;
            case 'topOnly':
                return cardStyles.wrapper;
            default:
                return cardStyles.wrapper;
        }
    };

    const actionTriggerHandler = async (type: 'reject' | 'accept' | 'remove'): Promise<void> => {
        setKongId(tokenId);

        switch (type) {
            case 'reject':
                setLoadingCardAction(OWNER_REQUESTS_TYPE.REJECT);
                await handleRejectOffer();
                setLoadingCardAction(null);
                setKongId(null);
                return;
            case 'accept':
                setLoadingCardAction(OWNER_REQUESTS_TYPE.ACCEPT);
                await handleAcceptOffer();
                setLoadingCardAction(null);
                setKongId(null);
                return;
            case 'remove':
                handleRemove();
                return;
            default:
                return;
        }
    };

    const onCardClick = (e) => {
        e.stopPropagation();
        e?.nativeEvent?.stopImmediatePropagation();
        const target = (Object.values(e.target)[0] as any).type;
        if (target === 'button' || target === 'a') {
            return;
        }
        router.push(`/kong/${tokenId}`);
    };

    if (cardType === 'topOnly') {
        return (
            <div
                className={`${cardStyles.topOnlyWrapper} ${active && cardStyles.whiteBorder} ${
                    kongIsPending && cardStyles.overlay
                }`}
            >
                <img className={cardStyles.topOnlyImage} src={imageSrc} alt="" />
                <div className={cardStyles.TopOnlyTitleWrapper}>
                    <h1 className={cardStyles.smallTitle}>{title}</h1>
                    <h1 className={cardStyles.smallSubTitle}>Total Boost: {boost}</h1>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className={getStylesBasedOnType(cardType)} onClick={onCardClick}>
                <div className={cardStyles.imageWrapper}>
                    <h1 className={cardStyles.title}>
                        <span className={cardStyles.ellipsisWrapper}>{title || 'Kong'}</span>
                        <span>{tokenId ? `#${tokenId}` : ''}</span>
                    </h1>
                    <img className={cardStyles.image} src={imageSrc} alt="" />
                </div>
                <div className={cardStyles.infoArea}>
                    <div className={cardStyles.infoAreaInner}>
                        <h3 className={cardStyles.properties}>
                            Owned by:{' '}
                            <a
                                target="_blank"
                                href={`https://opensea.io/${owner}`}
                                rel="noreferrer"
                            >
                                {/* {truncate(owner, !isMatching ? 5 : 3)} */}
                                {name}
                            </a>
                        </h3>
                        <h3 className={cardStyles.properties}>
                            Total Boost: <span>{boost}</span>
                        </h3>
                    </div>
                    <div className={cardStyles.divider}></div>
                    <div
                        className={`${cardStyles.attributes} ${
                            cardType === 'viewOnly' && cardStyles.attributesNoBorder
                        }`}
                    >
                        {attributes?.map((attr: any, index) => (
                            <div key={index} className={cardStyles.singleAttribute}>
                                <h3 className={cardStyles.attributeName}>
                                    {!isMatching ? attr.name : attr.icon}
                                </h3>
                                <h4 className={cardStyles.attributeValue}>{attr.value}</h4>
                            </div>
                        ))}
                    </div>
                    {cardType === 'member' && (
                        <div className={cardStyles.buttonArea}>
                            <RKLModalDialog
                                isOpen={confirmOpen}
                                hasOnClose
                                onClose={() => setConfirmOpen(false)}
                            >
                                <RKLConfirmDialogue title={title} />
                            </RKLModalDialog>
                            <button
                                onClick={() => actionTriggerHandler('remove')}
                                className={cardStyles.ownedButton}
                            >
                                <>Leave</>
                            </button>
                        </div>
                    )}
                    {cardType === 'owned' && (
                        <div className={cardStyles.buttonArea}>
                            <RKLModalDialog
                                isOpen={confirmOpen}
                                hasOnClose
                                onClose={() => setConfirmOpen(false)}
                            >
                                <RKLConfirmDialogue title={title} />
                            </RKLModalDialog>
                            {cardState !== 'removed' ? (
                                <button
                                    onClick={() => actionTriggerHandler('remove')}
                                    className={cardStyles.ownedButton}
                                >
                                    <>Remove</>
                                </button>
                            ) : (
                                <div className={cardStyles.ownedBtnRemoved}>Removed</div>
                            )}
                        </div>
                    )}
                    {cardType === 'offered' && (
                        <div
                            className={`${cardStyles.buttonArea} ${
                                cardState === 'accepted'
                                    ? cardStyles.accepted
                                    : cardState === 'rejected'
                                    ? cardStyles.rejected
                                    : ''
                            }`}
                        >
                            {cardState !== 'accepted' && cardState !== 'rejected' && (
                                <>
                                    <button
                                        onClick={() => actionTriggerHandler('reject')}
                                        className={`${cardStyles.btn} ${cardStyles.btnLeft} flex-center`}
                                        disabled={kongId === tokenId}
                                    >
                                        {loadingCardAction === OWNER_REQUESTS_TYPE.REJECT &&
                                        tokenId === kongId ? (
                                            <RKLLoadingSpinner height={30} width={30} />
                                        ) : (
                                            <>Reject</>
                                        )}
                                    </button>
                                    <button
                                        onClick={() => actionTriggerHandler('accept')}
                                        className={`${cardStyles.btn} ${cardStyles.btnRight} flex-center`}
                                        disabled={kongId === tokenId}
                                    >
                                        {loadingCardAction === OWNER_REQUESTS_TYPE.ACCEPT &&
                                        tokenId === kongId ? (
                                            <RKLLoadingSpinner height={30} width={30} />
                                        ) : (
                                            <>Accept</>
                                        )}
                                    </button>
                                </>
                            )}
                            {cardState === 'accepted' && <span>Accepted!</span>}
                            {cardState === 'rejected' && <span>Rejected</span>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RKLKongCard;
