import React from 'react';
import { DEFAULT_ICON_COLOR, IconProps, styledIcon } from './utils';

const RKLCloseIcon: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <path
                d="M1.50554 0.25831C1.16113 -0.0861032 0.602723 -0.0861032 0.25831 0.25831C-0.0861032 0.602723 -0.0861032 1.16113 0.25831 1.50554L2.75277 4L0.25831 6.49446C-0.0861032 6.83887 -0.0861032 7.39728 0.25831 7.74169C0.602723 8.0861 1.16113 8.0861 1.50554 7.74169L4 5.24723L6.49446 7.74169C6.83887 8.0861 7.39728 8.0861 7.74169 7.74169C8.0861 7.39728 8.0861 6.83887 7.74169 6.49446L5.24723 4L7.74169 1.50554C8.0861 1.16113 8.0861 0.602723 7.74169 0.25831C7.39728 -0.0861031 6.83887 -0.0861034 6.49446 0.25831L4 2.75277L1.50554 0.25831Z"
                fill={props.color || DEFAULT_ICON_COLOR}
            />
        </svg>
    );
};

export default RKLCloseIcon;
