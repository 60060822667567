import RKLCircleCheck from '../../icons/rkl-circle-check';
import RKLCircleClose from '../../icons/rkl-circle-close';
import style from './rkl-confirm-modal.module.css';

interface ConfirmDialogueProps {
    title?: string;
    customText?: string;
    failure?: boolean;
}

const RKLConfirmModal: React.FC<ConfirmDialogueProps> = ({ title, customText, failure }) => {
    return (
        <div className={`${style.dialogueContainer} ${style.confirmContainer}`}>
            {customText?.length > 0 ? (
                <h2 className={style.dialogueText}>{customText}</h2>
            ) : (
                <h2 className={style.dialogueText}>{title} was removed</h2>
            )}
            {!failure ? (
                <RKLCircleCheck
                    color="var(--green-color)"
                    style={{ width: '68px', height: '68px' }}
                />
            ) : (
                <RKLCircleClose
                    color="var(--gren-color)"
                    style={{ width: '68px', height: '68px' }}
                />
            )}
        </div>
    );
};

export default RKLConfirmModal;
