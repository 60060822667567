import { useState } from 'react';
import RKLButton from '../rkl-button';
import style from './rkl-remove-modal.module.css';
interface RemoveDialogueProps {
    title?: string;
    member?: boolean;
    handleConfirm: () => Promise<void>;
    onClose: () => void;
}

const RKLRemoveModal: React.FC<RemoveDialogueProps> = ({
    title,
    member,
    handleConfirm,
    onClose,
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const removeKongFromClub = async (): Promise<void> => {
        try {
            setLoading(true);
            await handleConfirm();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    if (member) {
        return (
            <div className={style.dialogueContainer}>
                <h2 className={style.dialogueText}>Do you want to leave this club?</h2>
                <div className={style.buttonsWrapper}>
                    <RKLButton
                        size="medDesktop"
                        color={'violet'}
                        borderOnly
                        loading={loading}
                        width={150}
                        onClick={removeKongFromClub}
                    >
                        Yes
                    </RKLButton>
                    <RKLButton
                        size="medDesktop"
                        color={'violet'}
                        onClick={onClose}
                        disabled={loading}
                    >
                        No
                    </RKLButton>
                </div>
            </div>
        );
    }

    return (
        <div className={style.dialogueContainer}>
            <h2 className={style.dialogueText}>
                Are you sure you want to remove {title} from the club?
            </h2>
            <RKLButton
                loading={loading}
                size="medDesktop"
                color={'violet'}
                onClick={removeKongFromClub}
            >
                Remove
            </RKLButton>
        </div>
    );
};

export default RKLRemoveModal;
