import React from 'react';

const IcoLogoWhite: React.FC = () => {
    return (
        <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="46" height="46" rx="13.5" stroke="#FEFEFE" />
            <path
                d="M30.1367 32.9253V15L33.4977 20.6017V29.5643H38.8193L40.7798 32.9253H30.1367Z"
                fill="#FEFEFE"
            />
            <path
                d="M20.6298 32.9258H17.1623L11.9938 23.8313C14.2355 23.8313 17.0869 23.5149 17.5066 21.3128C17.5378 21.1487 17.5549 20.9776 17.5549 20.7999C17.5549 20.7356 17.5526 20.6726 17.5483 20.6108C17.3851 18.2697 14.195 18.032 11.8482 18.032H8.72252L6.89062 15.0005H15.3958C16.4426 15.0005 20.5644 16.3844 20.5644 20.7999C20.5644 24.3322 18.1437 25.9841 16.966 26.4015L20.6298 32.9258Z"
                fill="#FEFEFE"
            />
            <path
                d="M25.9937 15H29.3958L23.8347 24.0945L29.5764 32.9253H25.9353L20.3672 24.0945L25.9937 15Z"
                fill="#FEFEFE"
            />
        </svg>
    );
};

export default IcoLogoWhite;
