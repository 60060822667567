import { useContext, useEffect, useMemo, useState } from 'react';
import { useUserKongs } from '../../contracts/rkl-collection';
import { Club } from '../../models/clubs';
import { KongsIdsClubs } from '../../models/kong';
import { ClubsContext } from '../context/clubs-provider';
import RKLBadge, { RKLBadgeType } from '../ui-kit/components/rkl-badge';
import RKLButton from '../ui-kit/components/rkl-button';
import RKLKongCard from '../ui-kit/components/rkl-kong-card';
import RKLModalLoader from '../ui-kit/components/rkl-modal-loader';
import { getMemberAttributes } from '../ui-kit/utils/getMemberAttributes';
import style from './kong-selection-modal.module.css';

export interface KongSelectionModalProps {
    onSubmitJoinRequest: (kongs, clubId) => void;
    onClose: () => void;
    clubId: string;
    clubInfo: Club;
}

const KongSelectionModal: React.FunctionComponent<KongSelectionModalProps> = ({
    onSubmitJoinRequest,
    onClose,
    clubId,
    clubInfo,
}: KongSelectionModalProps) => {
    const [kongsSelected, setKongsSelected] = useState([]);
    const { getPendingKongMembersData, pendingRequestKongs, loadingMyKongs, myKongsIdsClub } = useContext(ClubsContext);
    const { isLoading, metadataList } = useUserKongs(myKongsIdsClub);
    const [joinClubLoading, setJoinClubLoading] = useState<boolean>(false);

    const filteredMetadataList = useMemo(() => {
        return metadataList
            .map((m) => ({ ...m, id: m.id.toString() }))
            .filter((item) => !item.club)
            .filter((item) => !clubInfo?.roster?.includes(String(item.id)));
    }, [isLoading, metadataList, clubInfo]);

    const selectKongToJoin = (kongId: string): void => {
        const alreadySelected = kongsSelected.find((k) => k === kongId);
        if (!!alreadySelected) {
            return setKongsSelected([...kongsSelected.filter((k) => k !== kongId)]);
        }
        setKongsSelected([...kongsSelected, kongId]);
    };

    // This should setup to be able to check if a given Kong is pending approval (See RKLKongCard props)
    useEffect(() => {
        getPendingKongMembersData(clubId);
    }, [clubId]);

    return (
        <div className={`${style.chooseKongModalContent}`}>
            <div className={style.header}>Choose your kongs</div>
            <div className={`${style.kongsList}`}>
                {(isLoading || loadingMyKongs) && <RKLModalLoader />}
                {filteredMetadataList.length === 0 && !isLoading && !loadingMyKongs && (
                    <div className={style.emptyKongs}>You don't own any Kongs</div>
                )}
                {!loadingMyKongs && !isLoading && filteredMetadataList.length !== 0 && (
                    <div className={style.kongsListInner}>
                        {filteredMetadataList?.map((kong, idx) => (
                            <div key={idx} onClick={() => selectKongToJoin(kong.id)}>
                                <RKLKongCard
                                    imageSrc={kong.imagePath}
                                    tokenId={kong.id}
                                    cardType={'topOnly'}
                                    cardState={'removed'}
                                    title={kong.name}
                                    boost={kong?.total}
                                    attributes={getMemberAttributes({ ...kong.attributes })}
                                    active={!!kongsSelected.find((item) => item === kong.id)}
                                    kongIsPending={pendingRequestKongs.includes(kong.id)}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={style.separator} />
            <div className={style.bottomButtons}>
                <RKLButton
                    size="lgDesktop"
                    color="violet"
                    borderOnly
                    onClick={onClose}
                    width={177}
                    height={64}
                    disabled={joinClubLoading}
                >
                    Cancel
                </RKLButton>
                <RKLButton
                    size="lgDesktop"
                    color="violet"
                    onClick={async () => {
                        if (kongsSelected?.length === 0) return;
                        setJoinClubLoading(true);
                        await onSubmitJoinRequest(kongsSelected, clubId);
                        setJoinClubLoading(false);
                    }}
                    width={177}
                    height={66}
                    disabled={kongsSelected?.length === 0 || joinClubLoading}
                    loading={joinClubLoading}
                >
                    Join
                    {kongsSelected?.length > 0 && (
                        <RKLBadge
                            width={32}
                            height={30}
                            type={
                                kongsSelected?.length === 0
                                    ? RKLBadgeType.DISABLED
                                    : RKLBadgeType.BLACK
                            }
                            margin="0 5px"
                        >
                            {kongsSelected.length}
                        </RKLBadge>
                    )}
                </RKLButton>
            </div>
        </div>
    );
};

export default KongSelectionModal;
