import { useEffect, useState, useContext, useCallback } from 'react';
import BlockchainContext from '../context/blockchain-provider';
import { friendlyAddress } from '../../utils/string-manipulation';

export default function useEnsNme(address: string): string {
    const { defaultProvider, provider } = useContext(BlockchainContext);
    const [name, setName] = useState<string>();
    const getCurrentProvider = useCallback(() => {
        if (provider) {
            return provider;
        }

        return defaultProvider;
    }, [provider, defaultProvider]);
    const lookupEnsName = async (address: string) => {
        try {
            const provider_ = getCurrentProvider();
            if (provider_) {
                if (address && address !== '') {
                    const name = await provider_.lookupAddress(address);
                    if (name) {
                        setName(name);
                    }
                } else {
                    setName(undefined);
                }
            }
        } catch (error) {
            console.log(' lookupEnsName:: ', error);
        }
    };

    useEffect(() => {
        lookupEnsName(address);
    }, [address, provider, defaultProvider])


    return !name ? friendlyAddress(address || '') : name;
}
