import React from 'react';
import { IconProps, styledIcon } from './utils';

const RKLCircleClose: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 65C51.1208 65 65 51.1208 65 34C65 16.8792 51.1208 3 34 3C16.8792 3 3 16.8792 3 34C3 51.1208 16.8792 65 34 65ZM34 68C52.7777 68 68 52.7777 68 34C68 15.2223 52.7777 0 34 0C15.2223 0 0 15.2223 0 34C0 52.7777 15.2223 68 34 68Z"
                fill="#AE80F0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.1056 21.3168C46.6914 21.9026 46.6914 22.8523 46.1056 23.4381L34.9726 34.5711L45.4959 45.0945C46.0817 45.6803 46.0817 46.63 45.4959 47.2158C44.9101 47.8016 43.9604 47.8016 43.3746 47.2158L32.8513 36.6925L22.9376 46.6061C22.3518 47.1919 21.4021 47.1919 20.8163 46.6061C20.2305 46.0203 20.2305 45.0706 20.8163 44.4848L30.7299 34.5711L20.2066 24.0478C19.6208 23.462 19.6208 22.5123 20.2066 21.9265C20.7924 21.3407 21.7421 21.3407 22.3279 21.9265L32.8513 32.4498L43.9843 21.3168C44.5701 20.731 45.5198 20.731 46.1056 21.3168Z"
                fill="#AE80F0"
            />
        </svg>
    );
};

export default RKLCircleClose;
