import { gql } from '@apollo/client';

export const requestToJoinClubMutation = () => {
    return gql`
        mutation ($kongId: String!, $clubId: String!) {
            requestJoinClub(kongId: $kongId, clubId: $clubId)
        }
    `;
};

export const acceptRequestClub = () => {
    return gql`
        mutation ($kongId: String!, $clubId: String!) {
            acceptRequestJoinClub(kongId: $kongId, clubId: $clubId)
        }
    `;
};

export const cancelRequestClub = () => {
    return gql`
        mutation ($kongId: String!, $clubId: String!) {
            cancelRequestJoinClub(kongId: $kongId, clubId: $clubId)
        }
    `;
};

export const removeKongFromClub = () => {
    return gql`
        mutation ($kongId: String!) {
            leaveClub(kongId: $kongId)
        }
    `;
};

export const updateClubData = () => {
    return gql`
        mutation (
            $clubId: String!
            $name: String!
            $bio: String!
            $color1: ColorInput!
            $color2: ColorInput!
            $logoImage: String!
        ) {
            setClubInfo(
                input: {
                    clubId: $clubId
                    name: $name
                    bio: $bio
                    color1: $color1
                    color2: $color2
                    logoImage: $logoImage
                }
            )
        }
    `;
};
