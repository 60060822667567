import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useState } from 'react';
import {
    ACCOUNT_MENU,
    AUCTION_MENU,
    MENU,
    MENU_MOBILE,
    ROUTES,
    SITE_NAME,
} from '../../../../app.config';
import { mediumViewportWidth } from '../../../../media-queries';
import { isActiveMenu, isMarketplacePage } from '../../../../utils/router';
import Button from '../../../button';
import BlockchainContext from '../../../context/blockchain-provider';
import useMediaQuery from '../../../hooks/use-media-query';
import SocialLinks from '../../../social-links';
import style from '../../../styles/styles.module.css';
import RKLAccountIcon from '../../icons/rkl-account';
import RKLBurgerIcon from '../../icons/rkl-burger';
import RKLCloseIcon from '../../icons/rkl-close';
import RKLogoWhite from '../../icons/rkl-logo-white';
import RKLHeaderDropdown from '../rkl-header-dropdown';
import headerStyle from './rkl-header.module.css';

export interface HeaderProps {
    visible: boolean;
}

const Header: React.FC<HeaderProps> = () => {
    const router = useRouter();
    const { selectedAddress, onWalletDisconnect, onWalletConnect } = useContext(BlockchainContext);
    const isFullLayout = useMediaQuery(mediumViewportWidth);
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const onToggleMenu = useCallback(() => {
        setMenuIsOpen((menuIsOpen) => !menuIsOpen);
    }, []);
    const onClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const href = e.currentTarget.getAttribute('data-href');
        router.push(href);
        onToggleMenu();
    };

    const renderExtraDropdownMenuItem = <div onClick={onWalletDisconnect}>Disconnect</div>;

    return (
        <>
            <div className={`${headerStyle.headerMenu} ${!isFullLayout ? '' : headerStyle.desktop} ${menuIsOpen ? `${headerStyle.open}` : ''}`}>
                <div className={`${headerStyle.menuTitle}`}>
                    <div>
                        {!isFullLayout && (
                            <div className={`${headerStyle.menuClose}`} onClick={onToggleMenu}>
                                <RKLCloseIcon color="var(--black-color)" size={20} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={headerStyle.menuContainer}>
                    {(isFullLayout ? MENU : MENU_MOBILE).map((menuItem) => (
                        <div
                            className={`${headerStyle.menuItem} ${style.fontSizeExtraLarge} ${
                                isActiveMenu(router, menuItem.path) ||
                                isMarketplacePage(router, menuItem.path)
                                    ? style.fontColorWhite
                                    : style.fontColorBlack
                            }`}
                            key={menuItem.path}
                        >
                            <a onClick={onClickHandler} data-href={menuItem.path}>
                                {menuItem.title}
                            </a>
                        </div>
                    ))}
                </div>
                <div className={`${style.gridRow} ${headerStyle.socialMobile}`}>
                    <SocialLinks />
                </div>
                <div className={headerStyle.menuButtons}>
                    <Button
                        href="https://rumblekongleague.substack.com/"
                        target="_blank"
                        color="white"
                        size="small"
                    >
                        Blog
                    </Button>
                </div>
            </div>
            <div id="header" className={`${headerStyle.header} ${style.bgColorBlack}`}>
                <div className={`${headerStyle.header_inner} ${style.gridRow}`}>
                    <div className={`${style.gridRow} ${style.gridSpace}`}>
                        <Link href={ROUTES.DEFAULT}>
                            <a
                                className={`${style.gridRow} ${style.fontSizeMedium} ${style.fontColorWhite}`}
                            >
                                <RKLogoWhite />
                                {isFullLayout && (
                                    <span className={headerStyle.header_title}>{SITE_NAME}</span>
                                )}
                            </a>
                        </Link>
                        {isFullLayout && (
                            <div className={headerStyle.inlineMenuContainer}>
                                {MENU.map((menuItem) => (
                                    <div
                                        className={`${headerStyle.inlineMenuItem} ${
                                            style.fontSizeMedium
                                        } ${
                                            isActiveMenu(router, menuItem.path) ||
                                            isMarketplacePage(router, menuItem.path)
                                                ? style.fontColorRose
                                                : style.fontColorWhite
                                        }`}
                                        key={menuItem.path}
                                    >
                                        {menuItem.dropdown && (
                                            <RKLHeaderDropdown
                                                menu={AUCTION_MENU}
                                                title="Auction"
                                                showPlaceholderIfMenuIsOpen={false}
                                                header
                                            >
                                                {menuItem.title}
                                            </RKLHeaderDropdown>
                                        )}
                                        {!menuItem.dropdown && (
                                            <Link href={menuItem.path}>
                                                <a>{menuItem.title}</a>
                                            </Link>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={`${style.gridRow} ${headerStyle.socialContainer}`}>
                        <SocialLinks />
                    </div>
                    <div className={style.gridRow}>
                        <div className={headerStyle.connectWalletBtn}>
                            {!selectedAddress ? (
                                <Button color="white" size="small" onClick={onWalletConnect}>
                                    Connect
                                </Button>
                            ) : (
                                <RKLHeaderDropdown
                                    selectedAddress={selectedAddress}
                                    menu={ACCOUNT_MENU}
                                    extraMenuItem={renderExtraDropdownMenuItem}
                                    showPlaceholderIfMenuIsOpen={true}
                                >
                                    <RKLAccountIcon selectedAddress={selectedAddress} />
                                </RKLHeaderDropdown>
                            )}
                        </div>
                        {!isFullLayout && (
                            <span
                                className={headerStyle.header_menu_element}
                                onClick={onToggleMenu}
                            >
                                <RKLBurgerIcon />
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
