import { useRef, useState } from 'react';
import RKLButton from '../ui-kit/components/rkl-button';
import style from './upload-new-logo-desc-modal.module.css';

export interface UploadNewLogoDescModalProps {
    onUpload: (image: string, file: File) => void;
}

const UploadNewLogoDescModal: React.FunctionComponent<UploadNewLogoDescModalProps> = ({
    onUpload,
}: UploadNewLogoDescModalProps) => {
    const [error, setError] = useState<string | null>(null);
    const inputFileRef = useRef<HTMLInputElement>();

    const onClick = () => {
        setError(null);
        if (inputFileRef.current) {
            inputFileRef?.current?.click();
        }
    };

    const onInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e?.target?.files[0];
        if (!selectedFile) return;
        const MAX_MB = 0.2;
        const selectedFileSizeMb = selectedFile.size / (1024 * 1024);
        if (selectedFileSizeMb > MAX_MB) {
            return setError('The size of uploaded image is too big!');
        }
        const objectUrl = URL.createObjectURL(selectedFile);
        onUpload(objectUrl, selectedFile);
    };

    return (
        <div className={`${style.chooseKongModalContent} flex-center-column`}>
            <span className={style.title}>{error || 'Upload a new logo'}</span>
            <span className={style.description}>
                The maximum size of an uploaded image is 200kb
            </span>
            <input
                ref={inputFileRef}
                type="file"
                hidden
                onChange={onInputFileChange}
                accept=".jpg, .jpeg, .png"
            />
            <RKLButton size={'lgDesktop'} color={'violet'} onClick={onClick}>
                Upload
            </RKLButton>
        </div>
    );
};

export default UploadNewLogoDescModal;
