import React, { useEffect, useState } from 'react';
import RKLButton from '../ui-kit/components/rkl-button';
import RKLTextInput from '../ui-kit/components/rkl-text-input';
import style from './change-description-modal.module.css';

interface ChangeDescriptionModalProps {
    onClick: (name: string) => void;
    currentBio?: string;
    kong?: boolean;
}

const ChangeDescriptionModal: React.FunctionComponent<ChangeDescriptionModalProps> = ({
    onClick,
    currentBio,
    kong,
}) => {
    const [description, setDescription] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!currentBio && kong) return;
        setDescription(currentBio);
    }, [currentBio, kong]);

    return (
        <div className={`${style.changeClubNameModalContent} flex-center-column`}>
            {kong ? (
                <span className={`${style.title} ${style.whiteText}`}>
                    Write a short bio about your kong
                </span>
            ) : (
                <span className={style.title}>Describe your Club</span>
            )}

            <span className={style.description}>The maximum amount of symbols is 300</span>
            <div className={style.inputContainer}>
                <RKLTextInput
                    textArea
                    onChange={setDescription}
                    value={description}
                    placeholder={`Type ${kong ? 'Kong' : 'Club'} Description...`}
                    width={'100%'}
                    maxLength={300}
                />
            </div>
            <RKLButton
                loading={loading}
                disabled={loading}
                size={'lgDesktop'}
                color={'violet'}
                onClick={() => {
                    setLoading(true);
                    onClick(description);
                }}
            >
                Save
            </RKLButton>
        </div>
    );
};

export default React.memo(ChangeDescriptionModal);
