import React from 'react';
import { IconProps, styledIcon } from './utils';

const RKLBurgerIcon: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="29"
            height="12"
            viewBox="0 0 29 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <path d="M1 1H28" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M1 11H28" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};

export default RKLBurgerIcon;
