import React, {useEffect, useRef} from 'react';
import { createIcon } from '@download/blockies';

export interface RKLAccountIconProps {
    selectedAddress: string;
};

const RKLAccountIcon: React.FC<RKLAccountIconProps> = ({ selectedAddress }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (containerRef.current && containerRef.current.childNodes.length === 0) {
            const ico = createIcon({
                seed: selectedAddress,
                size: 12, 
                scale: 4,
                bgcolor: '#b87af7',
            });

            containerRef.current.appendChild(ico);
        }
    }, []);

    return (
        <div ref={containerRef}></div>
    );
};

export default RKLAccountIcon;
