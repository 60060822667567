import Head from 'next/head';
import React from 'react';
import ClubsProvider from '../context/clubs-provider';
import Footer from '../footer';
import Header from '../ui-kit/components/rkl-header';

export interface LayoutProps {
    headerIsVisible: boolean;
}

const Layout: React.FC<LayoutProps> = ({ headerIsVisible, children }) => {
    return (
        <>
            <Head>
                <title>Rumble Kong League</title>
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/images/favicon/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/images/favicon/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/images/favicon/favicon-16x16.png"
                />
                <link rel="manifest" href="/images/favicon/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/images/favicon/safari-pinned-tab.svg"
                    color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
            </Head>
            <ClubsProvider>
                <Header visible={headerIsVisible} />
            </ClubsProvider>
            <div id="main">{children}</div>
            <Footer />
        </>
    );
};

export default Layout;
