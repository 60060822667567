import RKLDefenseIcon from '../icons/rkl-defense';
import RKLEyesIcon from '../icons/rkl-eyes';
import RKLFlagIcon from '../icons/rkl-flag';
import RKLShootingIcon from '../icons/rkl-shooting';

export function getMemberAttributes({ shooting, vision, defense, finish }) {
    return [
        {
            name: 'Shooting',
            value: shooting,
            icon: (
                <RKLShootingIcon
                    color="#494a50"
                    style={{
                        height: '14.55px',
                        width: '14.55px',
                        marginRight: '2.5px',
                    }}
                />
            ),
            iconWhite: (
                <RKLShootingIcon
                    color="var(--white-color)"
                    style={{
                        height: '14.55px',
                        width: '14.55px',
                        marginRight: '2.5px',
                    }}
                />
            ),
        },
        {
            name: 'Defense',
            value: defense,
            icon: (
                <RKLDefenseIcon
                    color="#494a50"
                    style={{
                        height: '23.4px',
                        width: '17.45px',
                        marginRight: '2px',
                        // marginLeft: '8.8px'
                    }}
                />
            ),
            iconWhite: (
                <RKLDefenseIcon
                    color="var(--white-color)"
                    style={{
                        height: '23.4px',
                        width: '17.45px',
                        marginRight: '2.5px',
                        // marginLeft: '8.8px'
                    }}
                />
            ),
        },
        {
            name: 'Vision',
            value: vision,
            icon: (
                <RKLEyesIcon
                    color="#494a50"
                    style={{
                        height: '12.35px',
                        width: '16px',
                        marginRight: '2.5px',
                        // marginLeft: '8.8px'
                    }}
                />
            ),
            iconWhite: (
                <RKLEyesIcon
                    color="var(--white-color)"
                    style={{
                        height: '12.35px',
                        width: '16px',
                        marginRight: '2.5px',
                        // marginLeft: '8.8px'
                    }}
                />
            ),
        },
        {
            name: 'Finish',
            value: finish,
            icon: (
                <RKLFlagIcon
                    color="#494a50"
                    style={{
                        height: '15.27px',
                        width: '12.36px',
                        marginRight: '2.5px',
                        // marginLeft: '8.8px'
                    }}
                />
            ),
            iconWhite: (
                <RKLFlagIcon
                    color="var(--white-color)"
                    style={{
                        height: '15.27px',
                        width: '12.36px',
                        marginRight: '2.5px',
                        // marginLeft: '8.8px'
                    }}
                />
            ),
        },
    ];
}
