import React from 'react';
import RKLDiscordIcon from '../ui-kit/icons/rkl-discord';
import RKLInstagramIcon from '../ui-kit/icons/rkl-instagram';
import RKLTwitterIcon from '../ui-kit/icons/rkl-twitter';

const SocialLinks: React.FC = () => (
    <>
        <a href="https://twitter.com/RumbleKongs" rel="noreferrer" target="_blank">
            <RKLTwitterIcon />
        </a>
        <a href="https://www.instagram.com/therumblekongs/" rel="noreferrer" target="_blank">
            <RKLInstagramIcon />
        </a>
        <a href="https://discord.gg/rkl" rel="noreferrer" target="_blank">
            <RKLDiscordIcon />
        </a>
    </>
);

export default SocialLinks;
