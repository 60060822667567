import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { Menu, SUBMENU_VALIDATIONS } from '../../../../models/menu';
import { friendlyAddress } from '../../../../utils/string-manipulation';
import { ClubsContext } from '../../../context/clubs-provider';
import css from './rkl-header-dropdown.module.css';

export interface RKLHeaderDropdownProps {
    menu: Menu[];
    selectedAddress?: string;
    header?: boolean;
    title?: string;
    extraMenuItem?: React.ReactNode;
    showPlaceholderIfMenuIsOpen?: boolean;
}

const RKLHeaderDropdown: React.FC<RKLHeaderDropdownProps> = ({
    selectedAddress,
    children,
    menu,
    header,
    title,
    extraMenuItem,
    showPlaceholderIfMenuIsOpen,
}) => {
    const router = useRouter();
    const { myClubs } = useContext(ClubsContext);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedMenu, setSelectedMenu] = useState<Menu>(null);
    const [currentMenu, setCurrentMenu] = useState<Menu[]>(menu);

    useEffect(() => {
        setCurrentMenu(
            myClubs?.length > 0
                ? menu
                : menu.filter((item) => item?.submenuValidate !== SUBMENU_VALIDATIONS.MY_CLUBS),
        );
    }, [myClubs]);

    return (
        <div
            className={`${css.dropdown}`}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <div className={`${css.placeholder} ${showPlaceholderIfMenuIsOpen ? css.show : ''}`}>
                {children}
            </div>
            {open && (
                <div className={`${css.menu} ${header && css.header}`}>
                    {!!selectedAddress && (
                        <div className={`${css.menuItem} ${css.placeholderLabel}`}>
                            {friendlyAddress(selectedAddress)}
                        </div>
                    )}
                    {!!title && <div className={`${css.menuItem} ${css.title}`}>{title}</div>}
                    {currentMenu
                        .map((item) => {
                            if (
                                item.submenuValidate &&
                                item.submenuValidate === SUBMENU_VALIDATIONS.MY_CLUBS &&
                                myClubs?.length === 0
                            ) {
                                return { ...item, visible: false };
                            }
                            return { ...item, visible: true };
                        })
                        .filter((item) => item.visible)
                        .map((item, index) => (
                            <div
                                key={index}
                                className={`${item.submenu ? css.menuItemList : css.menuItem}`}
                                onMouseOver={() => {
                                    if (item?.submenuValidate === SUBMENU_VALIDATIONS.MY_CLUBS) {
                                        return setSelectedMenu(item);
                                    }
                                }}
                                onClick={() => {
                                    if (item?.submenuValidate !== SUBMENU_VALIDATIONS.MY_CLUBS) {
                                        router.push(item.path);
                                        setOpen(false);
                                    }
                                }}
                            >
                                <div
                                    key={index}
                                    className={`${
                                        selectedMenu?.title === item.title &&
                                        item.submenu &&
                                        css.selectedMenu
                                    } ${item.submenu && css.submenuWrapper}`}
                                    onClick={() => {
                                        if (
                                            item?.submenuValidate !== SUBMENU_VALIDATIONS.MY_CLUBS
                                        ) {
                                            router.push(item.path);
                                            setOpen(false);
                                        }
                                    }}
                                >
                                    <a>{item.title}</a>
                                </div>
                                <div>
                                    {selectedMenu?.title === item.title &&
                                        item.submenuValidate === SUBMENU_VALIDATIONS.MY_CLUBS &&
                                        myClubs?.map((menuItem) => (
                                            <Link key={menuItem.id} href={`/club/${menuItem.id}`}>
                                                <div key={menuItem.id} className={css.subMenuItem}>
                                                    <a className={css.subMenuItemLink}>
                                                        {menuItem.name}
                                                    </a>
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        ))}
                    {extraMenuItem && (
                        <div className={`${css.menuItem} ${css.menuExtra}`} key="extra-menu-item">
                            {extraMenuItem}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default RKLHeaderDropdown;
