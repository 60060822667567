import { Dispatch, SetStateAction } from 'react';
import style from './rkl-slide-bar.module.css';

export interface RKLSlideBarProps {
    value: number;
    setValue: Dispatch<SetStateAction<number>>;
}

const RKLSlideBar: React.FunctionComponent<RKLSlideBarProps> = ({
    value,
    setValue,
}: RKLSlideBarProps) => {
    return (
        <div className={style.rangeBarContainer}>
            <input
                className={style.violetRange}
                id="range"
                type="range"
                min="0"
                max="100"
                value={value}
                onChange={(e) => setValue(Number(e.target.value))}
            />
            <input
                className={style.topRange}
                id="range"
                type="range"
                min="0"
                max="100"
                value={value}
                onChange={(e) => setValue(Number(e.target.value))}
            />
        </div>
    );
};

export default RKLSlideBar;
