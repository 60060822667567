import { useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Club, UpdateClubPayload } from '../../models/clubs';
import { Kong, KongsIdsClubs } from '../../models/kong';
import {
    acceptRequestClub,
    cancelRequestClub,
    removeKongFromClub,
    requestToJoinClubMutation,
    updateClubData,
} from '../../services/api/mutations';
import {
    getAvailableClubs,
    getClubDataById,
    getJoinedClubsKongs,
    getKongsMemberData,
    getKongTotalBoost,
    getMyKongs,
    getMyKongsIds,
    getPendingClubRequests,
    getPendingKongsRequest,
} from '../../services/api/queries';
import getSlugByClubName from '../../utils/get-slug-by-club-name';
import AddMemberModalError from '../add-member-modal-error';
import ChangeDescriptionModal from '../change-description-modal';
import ChangeNameModal from '../change-name-modal';
import ChooseKongModalSuccess from '../choose-kong-modal-success';
import KongSelectionModal from '../kong-selection-modal';
import LeaveClubMemberModal from '../leave-member-modal-error';
import SuccessModal from '../success-modal';
import RKLRemoveModal from '../ui-kit/components/rkl-remove-modal';
import UploadNewLogoDescModal from '../upload-new-logo-desc-modal';
import UploadNewLogoResizeModal from '../upload-new-logo-resize-modal';
import { AppContext } from './app-context';
import BlockchainContext from './blockchain-provider';

export enum OWNER_REQUESTS_TYPE {
    ACCEPT,
    REJECT,
    REMOVE,
}

export interface ClubsContextType {
    handleJoin: (clubId: string | number) => void;
    onSubmitJoinRequest: (kongs: number[], clubId: string) => void;
    editClubName: () => void;
    editClubDescription: () => void;
    uploadNewLogo: () => void;
    onHandleKongRequests: (
        kong: Kong,
        callback: any,
        type: OWNER_REQUESTS_TYPE,
        owner?: boolean,
        clubId?: string,
    ) => void;
    resizeNewLogo: (image: string, file: File) => void;
    isOwner: boolean;
    setIsOwner: React.Dispatch<React.SetStateAction<boolean>>;
    removedRows: any;
    setRemovedRows: React.Dispatch<any>;
    availableClubs: Club[];
    setAvailableClubs: React.Dispatch<React.SetStateAction<Club[]>>;
    loadingClubs: boolean;
    getClubById: (clubId: string) => Promise<void>;
    kongMembers: Kong[];
    currentClub: Club;
    loadingClub: boolean;
    loadingKongMembers: boolean;
    loadingMyKongs: boolean;
    pendingRequestKongs: Kong[];
    loadingPendingClubRequest: boolean;
    setPendingRequestKongs: React.Dispatch<React.SetStateAction<Kong[]>>;
    updateClubDataHandler: (club: UpdateClubPayload) => Promise<void>;
    updateClubDataLoading: boolean;
    isMember: boolean;
    myClubs: Club[];
    myKongsIds: any[];
    loadingPendingKongsRequest: boolean;
    pendingMyKongsRequest: { clubId: string; kongId: string }[];
    getPendingKongMembersData: any;
    getMyKongsPendingRequests: any;
    myKongs: any[];
    emptyClub: boolean;
    pendingRequestQty: string;
    loadingMyKongsIds: boolean;
    setPendingRequestQty: React.Dispatch<React.SetStateAction<string>>;
    setCurrentClub: React.Dispatch<React.SetStateAction<Club>>;
    myKongsIdsClub: KongsIdsClubs[];
}

export interface ClubsProviderProps {}

export const ClubsContext = createContext<ClubsContextType>({
    handleJoin: null,
    onSubmitJoinRequest: () => {},
    isOwner: false,
    setIsOwner: () => {},
    editClubName: () => {},
    editClubDescription: () => {},
    uploadNewLogo: () => {},
    resizeNewLogo: () => {},
    onHandleKongRequests: () => {},
    removedRows: [],
    setRemovedRows: () => {},
    availableClubs: [],
    setAvailableClubs: () => {},
    loadingClubs: false,
    getClubById: null,
    kongMembers: null,
    currentClub: null,
    loadingClub: false,
    loadingKongMembers: false,
    loadingMyKongs: true,
    loadingMyKongsIds: true,
    pendingRequestKongs: [],
    loadingPendingClubRequest: false,
    setPendingRequestKongs: null,
    updateClubDataHandler: null,
    updateClubDataLoading: false,
    isMember: false,
    myClubs: null,
    myKongsIds: null,
    getPendingKongMembersData: () => {},
    getMyKongsPendingRequests: () => {},
    myKongs: null,
    loadingPendingKongsRequest: true,
    pendingMyKongsRequest: null,
    emptyClub: false,
    pendingRequestQty: null,
    setPendingRequestQty: null,
    setCurrentClub: null,
    myKongsIdsClub: null,
});

const ClubsProvider: React.FC<ClubsProviderProps> = ({ children }) => {
    const router = useRouter();

    const { closeModal, openModel } = useContext(AppContext);
    const { selectedAddress, onWalletConnect } = useContext(BlockchainContext);

    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [isMember, setIsMember] = useState<boolean>(false);
    const [removedRows, setRemovedRows] = useState<any>([]);

    const [loadingClubs, setLoadingClubs] = useState<boolean>(false);
    const [getClubs] = useLazyQuery(getAvailableClubs());
    const [getKongsBoost] = useLazyQuery(getKongTotalBoost());
    const [availableClubs, setAvailableClubs] = useState<Club[]>([]);
    const [myClubs, setMyClubs] = useState<Club[]>([]);

    const [loadingClub, setLoadingClub] = useState<boolean>(false);
    const [emptyClub, setEmptyClub] = useState<boolean>(false);
    const [getClub] = useLazyQuery(getClubDataById());
    const [currentClub, setCurrentClub] = useState<Club>(null);

    const [loadingKongMembers, setLoadingKongMembers] = useState<boolean>(false);
    const [kongMembers, setKongMembers] = useState<Kong[]>([]);
    const [getKongMembers] = useLazyQuery(getKongsMemberData());
    const [_getJoinedClubsKongs] = useLazyQuery(getJoinedClubsKongs());

    const [loadingPendingClubRequest, setLoadingPendingClubRequest] = useState<boolean>(false);
    const [pendingRequestKongs, setPendingRequestKongs] = useState<Kong[]>([]);
    const [pendingRequestQty, setPendingRequestQty] = useState<string>(null);
    const [_getPendingClubRequests] = useLazyQuery(getPendingClubRequests());

    const [loadingPendingKongsRequest, setLoadingPendingKongsRequest] = useState<boolean>(true);
    const [pendingMyKongsRequest, setPendingMyKongsRequest] = useState<
        { clubId: string; kongId: string }[]
    >([]);
    const [_getPendingKongsRequest] = useLazyQuery(getPendingKongsRequest());

    const [_requestToJoinClubMutation] = useMutation(requestToJoinClubMutation());

    const [_acceptRequestClub] = useMutation(acceptRequestClub());
    const [_rejectRequestClub] = useMutation(cancelRequestClub());
    const [_removeKongFromClub] = useMutation(removeKongFromClub());

    const [updateClubDataLoading, setUpdateClubDataLoading] = useState<boolean>(false);
    const [_updateClubData] = useMutation(updateClubData());

    const [loadingMyKongs, setLoadingMyKongs] = useState<boolean>(true);
    const [loadingMyKongsIds, setLoadingMyKongsIds] = useState<boolean>(true);
    const [myKongsIdsClub, setMyKongsIdsClub] = useState<KongsIdsClubs[]>([]);
    const [myKongsIds, setMyKongsIds] = useState<string[]>([]);
    const [myKongs, setMyKongs] = useState([]);
    const [_getMyKongs] = useLazyQuery(getMyKongs());
    const [_getMyKongsIds] = useLazyQuery(getMyKongsIds());

    const onHandleKongRequests = async (
        kong: Kong,
        callback: (number?: string | number, title?: string) => void,
        type: OWNER_REQUESTS_TYPE,
        member?: boolean,
        clubId?: string,
    ): Promise<void> => {
        if (type === OWNER_REQUESTS_TYPE.REMOVE) {
            openModel({
                content: (
                    <RKLRemoveModal
                        title={kong.name}
                        onClose={closeModal}
                        member={member}
                        handleConfirm={async () => {
                            try {
                                await _removeKongFromClub({
                                    variables: {
                                        kongId: kong.id,
                                        clubId: currentClub?.id || clubId,
                                    },
                                });
                                if (isMember) {
                                    if (callback) {
                                        callback();
                                    }
                                    setKongMembers([
                                        ...kongMembers.filter((k) => k?.id !== kong?.id),
                                    ]);
                                    return openSuccessSavedChanges(
                                        `You left the club ${currentClub?.name || ''}`,
                                    );
                                }
                                callback(kong.id, kong.name);
                                closeModal();
                                setTimeout(() => {
                                    setKongMembers([
                                        ...kongMembers.filter((m) => m.id !== kong.id),
                                    ]);
                                    setRemovedRows([]);
                                }, 2000);
                            } catch (error) {
                                openLeaveClubErrorModal(
                                    isOwner
                                        ? `Can't remove yet. Need to wait 7 days from the join time`
                                        : 'By joining to club, you must stay at least for 7 days.',
                                    isOwner ? `Can't remove member` : `You can't leave this club`,
                                );
                            }
                        }}
                    />
                ),
                onClose: true,
                borderColor: 'var(--grey-color)',
            });
        } else {
            if (type === OWNER_REQUESTS_TYPE.ACCEPT) {
                if (kongMembers?.length + 1 > 30) {
                    openModel({
                        content: <AddMemberModalError onClose={closeModal} />,
                        onClose: false,
                        width: '564px',
                        height: '332px',
                    });
                    return;
                }
                setCurrentClub({
                    ...currentClub,
                    totalClubBoost: currentClub?.totalClubBoost + kong?.totalBoost,
                    roster: [...currentClub.roster, kong?.id],
                });
                setKongMembers([kong, ...kongMembers]);
                await _acceptRequestClub({
                    variables: { kongId: kong.id, clubId: currentClub.id },
                });
            }
            if (type === OWNER_REQUESTS_TYPE.REJECT) {
                await _rejectRequestClub({
                    variables: { kongId: kong.id, clubId: currentClub.id },
                });
            }
            callback(kong.id, kong.name);
            closeModal();
        }
    };

    const onChooseKong = (clubId: string): void => {
        if (!selectedAddress) {
            onWalletConnect();
            return closeModal();
        }
        closeModal();
        openModel({
            content: (
                <ClubsProvider>
                    <KongSelectionModal
                        onSubmitJoinRequest={onSubmitJoinRequest}
                        onClose={() => closeModal()}
                        clubId={clubId}
                        clubInfo={currentClub}
                    />
                </ClubsProvider>
            ),
            onClose: false,
            borderColor: 'var(--grey-color)',
            width: '920px',
            height: '80vh',
            padding: '0px',
            topView: true,
        });
    };

    const onSubmitJoinRequest = async (kongs: number[], clubId: string): Promise<void> => {
        try {
            for (let i = 0; i < kongs.length; i++) {
                await _requestToJoinClubMutation({
                    variables: { kongId: kongs[i].toString(), clubId },
                });
            }
            getMyKongsPendingRequests();
            getPendingKongMembersData(clubId);
            closeModal();
            openModel({
                content: <ChooseKongModalSuccess />,
                onClose: true,
            });
        } catch (error) {
            console.log('An error has occurred while to requesting joining', error);
        }
    };

    const editClubName = (): void => {
        openModel({
            content: (
                <ChangeNameModal
                    currentName={currentClub.name}
                    onClick={async (newName: string) => {
                        await updateClubDataHandler({ name: newName });
                        closeModal();
                        openSuccessSavedChanges();
                    }}
                />
            ),
            onClose: true,
            width: '704px',
            height: '356px',
        });
    };

    const editClubDescription = (): void => {
        openModel({
            content: (
                <ChangeDescriptionModal
                    currentBio={currentClub.bio}
                    onClick={async (newDescription: string) => {
                        await updateClubDataHandler({
                            bio: newDescription,
                        });
                        closeModal();
                        openSuccessSavedChanges();
                    }}
                />
            ),
            onClose: true,
            width: '704px',
            height: '472px',
        });
    };

    const uploadNewLogo = (): void => {
        openModel({
            content: (
                <UploadNewLogoDescModal
                    onUpload={(image: string, file: File) => {
                        resizeNewLogo(image, file);
                    }}
                />
            ),
            onClose: true,
            width: '562px',
            height: '332px',
        });
    };

    const resizeNewLogo = (image: string, file: File): void => {
        openModel({
            content: (
                <UploadNewLogoResizeModal
                    image={image}
                    onSave={async (output: string) => {
                        await updateClubDataHandler({
                            logoImage: output.split('base64,')[1],
                        });
                        closeModal();
                        openSuccessSavedChanges();
                    }}
                />
            ),
            onClose: true,
            width: '562px',
            height: '602px',
        });
    };

    const openSuccessSavedChanges = (title?: string): void => {
        openModel({
            content: <SuccessModal title={title || 'Your changes were saved!'} />,
            onClose: true,
        });
    };

    const openLeaveClubErrorModal = (message: string, title: string): void => {
        openModel({
            content: <LeaveClubMemberModal onClose={closeModal} message={message} title={title} />,
            onClose: true,
        });
    };

    const getClubsWithTotalBoost = async (): Promise<void> => {
        try {
            setLoadingClubs(true);
            const { data } = await getClubs();
            if (!data) return;
            const clubs: Club[] = data?.clubs;
            const clubRoster = clubs
                .reduce((res, item) => {
                    res.push(...item.roster);
                    return res;
                }, [])
                .filter((kongId, index, kongsIds) => kongsIds.indexOf(kongId) === index);
            const { data: kongs } = await getKongsBoost({
                variables: { kongId: clubRoster },
            });
            const clubsWithTotalBoost = clubs.map((club, index) => {
                if (club.roster.length === 0) {
                    return {
                        ...club,
                        totalClubBoost: 0,
                        visible: true,
                        idx: index,
                    };
                } else {
                    const totalClubBoost = club.roster.reduce((res, item) => {
                        const boost = kongs.kong.find((kong) => kong.id === item);
                        res += boost.totalBoost;
                        return res;
                    }, 0);
                    return {
                        ...club,
                        totalClubBoost,
                        visible: true,
                        idx: index,
                    };
                }
            });
            setAvailableClubs(clubsWithTotalBoost);
            setLoadingClubs(false);
        } catch (error) {
            setLoadingClubs(false);
        }
    };

    const getKongMembersData = async (roster: string[]): Promise<void> => {
        if (roster?.length === 0) setKongMembers([]);

        const { data: kongsData } = await getKongMembers({ variables: { kongId: roster } });
        const { data } = await _getJoinedClubsKongs({ variables: { kongId: roster } });
        const selectedAddressKongs: Kong[] = kongsData.kong.filter(
            (kong: Kong) => kong.owner.toLowerCase() === (selectedAddress || '').toLowerCase(),
        );
        const kongMembersWithJoinTimestamp = kongsData.kong.map((kong) => ({
            ...kong,
            joinTimestamp: data.joinedClubsKongs.find((item) => item.kongId === kong.id)
                ?.joinTimestamp,
        }));

        setKongMembers(kongMembersWithJoinTimestamp);
        setIsMember(selectedAddressKongs.length !== 0);
        setLoadingKongMembers(false);
    };

    const getPendingKongMembersData = async (clubId: string): Promise<void> => {
        const { data: pendingReqData } = await _getPendingClubRequests({
            variables: { clubId },
        });
        const pendings = pendingReqData.pendingJoinClubRequestsClubs;
        setPendingRequestQty(pendings?.length?.toString());
        const { data: kongsData } = await getKongMembers({
            variables: { kongId: pendings.map((p) => p.kongId) },
        });
        setPendingRequestKongs([...kongsData.kong.map((k) => ({ ...k, visible: true }))]);
        setLoadingPendingClubRequest(false);
    };

    const getMyKongsPendingRequests = async (): Promise<void> => {
        try {
            const { data: pendingReqData } = await _getPendingKongsRequest({
                variables: { kongsIds: myKongsIds },
            });
            const pendings = pendingReqData.pendingJoinClubRequestsKongs;
            setPendingMyKongsRequest([...pendings]);
            setLoadingPendingKongsRequest(false);
        } catch (error) {
            console.log('An error has occurred while getting pending request for my kongs');
            setLoadingPendingKongsRequest(false);
        }
    };

    const getClubByIdData = async (clubId: string): Promise<Club> => {
        const { data } = await getClub({
            variables: { clubId },
        });
        const club: Club = data?.club[0];

        if (selectedAddress && club?.owner) {
            setIsOwner(club?.owner?.toLowerCase() === selectedAddress?.toLowerCase());
        } else {
            setIsOwner(false);
        }

        const totalClubBoost = availableClubs.find((c) => c.id === club?.id)?.totalClubBoost;
        setCurrentClub({ ...club, totalClubBoost });
        return club;
    };

    const getClubById = async (clubId: string): Promise<void> => {
        setLoadingClub(true);
        setEmptyClub(false);
        try {
            const club = await getClubByIdData(clubId);
            setLoadingClub(false);
            if (!club) {
                setEmptyClub(true);
                setLoadingClub(false);
                setLoadingKongMembers(false);
                setLoadingPendingClubRequest(false);
                return;
            }
            setLoadingKongMembers(true);
            setLoadingPendingClubRequest(true);

            await getPendingKongMembersData(clubId);
            await getKongMembersData(club.roster);
        } catch (error) {
            setLoadingClub(false);
            setLoadingKongMembers(false);
            setLoadingPendingClubRequest(false);
        }
    };

    const updateClubDataHandler = useCallback(
        async (club: UpdateClubPayload): Promise<void> => {
            setUpdateClubDataLoading(true);
            
            try {
                await _updateClubData({
                    variables: {
                        ...currentClub,
                        ...club,
                        bio: club.bio === undefined ? currentClub.bio : club.bio === '' ? ' ' : club.bio,
                        logoImage: club?.logoImage == null ? '' : club?.logoImage,
                        color1: club?.color1 || {
                            r: currentClub.color1[0],
                            g: currentClub.color1[1],
                            b: currentClub.color1[2],
                        },
                        color2: club?.color2 || {
                            r: currentClub.color2[0],
                            g: currentClub.color2[1],
                            b: currentClub.color2[2],
                        },
                        clubId: currentClub.id,
                    },
                });
                await getClubByIdData(currentClub.id.toString());
                // we should also update the route path
                if (club.name) {
                    router.replace({
                        pathname: `/club/${getSlugByClubName(club.name)}`
                    });
                }
                // We should also update clubs info when the club info is updated
                await getClubsWithTotalBoost();
                setUpdateClubDataLoading(false);
            } catch (error) {
                console.log(error);
                setUpdateClubDataLoading(false);
            }
        },
        [currentClub],
    );

    const getMyKongsIDs = async (owner: string): Promise<void> => {
        try {
            const response = await _getMyKongsIds({ variables: { owner } });
            const kongsIds = response.data.myKongs.map((kong) => kong.id);
            const kongsIdsClubs = response.data.myKongs.map((kong) => ({
                id: kong.id,
                club: kong.club,
            }));
            const kongsData = await _getMyKongs({ variables: { owner } });
            
            setMyKongsIds([...kongsIds]);
            setMyKongsIdsClub(kongsIdsClubs);
            setMyKongs([...kongsData.data.myKongs]);
            
            setLoadingMyKongsIds(false);
            setLoadingMyKongs(false);
        } catch (error) {
            setLoadingMyKongs(false);
            setLoadingMyKongsIds(false);
        }
    };

    useEffect(() => {
        getClubsWithTotalBoost();
    }, []);

    useEffect(() => {
        if (!selectedAddress) return;
        getMyKongsIDs(selectedAddress);
    }, [selectedAddress]);

    useEffect(() => {
        getMyKongsPendingRequests();
    }, [myKongsIds]);

    useEffect(() => {
        if (!selectedAddress || availableClubs?.length === 0) return;
        const _myClubs = availableClubs.filter(
            (club) => club?.owner?.toLowerCase() === selectedAddress?.toLowerCase(),
        );
        setMyClubs([..._myClubs]);
    }, [availableClubs, selectedAddress]);

    return (
        <ClubsContext.Provider
            value={{
                handleJoin: onChooseKong,
                onSubmitJoinRequest,
                isOwner,
                isMember,
                setIsOwner,
                editClubName,
                editClubDescription,
                uploadNewLogo,
                resizeNewLogo,
                onHandleKongRequests,
                removedRows,
                setRemovedRows,
                availableClubs,
                setAvailableClubs,
                loadingClubs,
                loadingClub,
                loadingMyKongs,
                getClubById,
                kongMembers,
                currentClub,
                loadingKongMembers,
                pendingRequestKongs,
                loadingPendingClubRequest,
                setPendingRequestKongs,
                updateClubDataLoading,
                updateClubDataHandler,
                myClubs,
                myKongsIds,
                getPendingKongMembersData,
                getMyKongsPendingRequests,
                myKongs,
                loadingPendingKongsRequest,
                pendingMyKongsRequest,
                loadingMyKongsIds,
                emptyClub,
                pendingRequestQty,
                setPendingRequestQty,
                setCurrentClub,
                myKongsIdsClub,
            }}
        >
            {children}
        </ClubsContext.Provider>
    );
};

export default ClubsProvider;
